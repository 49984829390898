body,
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.map-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.map {
  height: 100%;
  width: 100%;
}

/* .controls {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background-color: #ffffffbb;
  border-radius: 25px;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  padding: 10px;
  justify-content: center;
  align-items: center;
} */

.mode-buttons {
  display: flex;
  gap: 10px;
  /* center horizontally */
  justify-content: center;
  /* center vertically */
  align-items: center;
}

.mode-button {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #5d5959;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.mode-button:hover {
  background-color: #e0e0e0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.mode-button.selected {
  background-color: #007bff;
  color: white;
  border: 2px solid #007bff;
}

.controls {
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 20px; /* Move to the far left */
  display: flex;
  flex-direction: column; /* Stack input fields vertically */
  background-color: #ffffffcc;
  border-radius: 25px;
  gap: 10px;
  z-index: 10;
  padding: 10px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  transition: all 0.3s ease; /* Smooth transition for width and height */
}

#instructions {
  position: absolute;
  top: 80px; /* Adjust to position right under the controls with some spacing */
  right: 50px; /* Align with the input container */
  width: 320px;
  max-height: 70%;
  background-color: #ffffffcc;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: 20;
  font-family: sans-serif;
  display: flex; /* Keep flex for alignment */
  flex-direction: column; /* Stack items vertically */
}

.direction-header {
  display: flex;
  flex-direction: row;
  background-color: #c6f6d59b;
  padding: 20px;
  align-items: center; /* Center items vertically */
}

.direction-header span {
  display: flex; /* Ensure icons are aligned */
  align-items: center; /* Center icons vertically */
  margin-right: 10px; /* Adjust space between icon and text */
}

.direction-header p {
  margin: 0; /* Remove margin to align text with icons */
  font-size: 1rem;
}

#instructions ol {
  padding: 20px 20px 20px 40px;
}

.map-mode-buttons {
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 10;
  background-color: #ffffffbb;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.input-container {
  width: 100%;
  padding: 15px !important;
  border-radius: 10px !important;
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  margin-bottom: 5px; /* Space between input fields */
  background-color: #ffffff; /* Set background color */
  border-radius: 5px; /* Match input field border radius  */
  padding: 10px; /* Add padding to create space around the icon and input */
  position: relative;
}

.input-container input {
  padding-left: 5px;
  border: none; /* Remove border from input */
  outline: none; /* Remove outline on focus */
  flex: 1; /* Allow input to take remaining space */
  font-size: 16px; /* Match input font size */
  background: transparent; /* Make background transparent to show container's background */
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.danger .mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", sans-serif;
  padding: 0;
  width: 180px;
}

.resetButton {
  background-color: #276749 !important;
  color: white !important;
  height: 50px;
  width: 50px;
  font-size: 20px;
  border-radius: 10px;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Optional, for text alignment */
}

/* .resetButton :hover {
  background-color: rgb(255 110 110) !important;
  height: 40px;
  width: 10px;
  font-size: 20px;
  border-radius: 10px;
} */

.footerContainer {
  background-color: white;
  border-radius: 25px; /* Adjusted for pill shape */
  color: gray;
  position: absolute; /* Positioning it at the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  bottom: 20px; /* Distance from the bottom */
  padding: 10px 20px; /* Padding for left and right */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  font-size: 12px;
}
.footerContainer a {
  text-decoration: underline; /* Add underline to the link */
  color: inherit; /* Ensure the link inherits the color from the footer */
}

.onboarding-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.popup-content {
  background-color: white;
  padding: 40px; /* Adjusted padding */
  border-radius: 8px;
  width: 50%;
  height: auto; /* Changed to auto to fit content */
  max-height: 80%; /* Limit height for better visibility */
  overflow-y: auto; /* Allow scrolling if content is too long */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex; /* Added to enable flexbox */
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Align items to the top */
  align-items: center; /* Center content horizontally */
}

.popup-content p {
  margin-bottom: 10px;
}

h2 {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 30px;
}

.section {
  text-align: left; /* Left align the text in sections */
  width: 100%; /* Full width for sections */
  margin-bottom: 20px; /* Space between sections */
}

h3 {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 24px; /* Adjusted font size for section headers */
}

.close-button {
  margin-top: auto; /* Push button to the bottom */
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #0056b3;
}

.data-limitation-banner {
  background-color: #ffcc00; /* Yellow background */
  color: #333; /* Dark text color */
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 15px; /* Space below the banner */
  width: 100%;
}

ol {
  padding-left: 20px; /* Add padding to the left of the ordered list */
  margin-bottom: 20px; /* Space below the list */
}

ol li {
  margin-bottom: 5px; /* Space between list items */
  text-align: left; /* Ensure text is left-aligned */
}
